<template>
  <v-container fill-hight fluid style="background-color: #ebedf3" class="pa-0">
    <Selecionar :selecionar="selecionar">
      <v-select
        dense
        hide-details
        :items="leiloes"
        item-text="nome"
        v-model="leilaoSelecionado"
        return-object
        style="max-width: 400px"
        background-color="#ffffff"
        single-line
        @change="changeLeilao"
        label="Selecione um Leilão"
        outlined
        :loading="loadingLeiloes"
      >
        <template v-slot:item="{ item }">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title> {{ item.id }} - {{ item.nome }} - {{ item.titulo }} - {{ item.dataLeilao | formataDataHora }} </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider class="mt-2"></v-divider>
        </template>
        <template v-slot:selection="{ item }"> {{ item.id }} - {{ item.nome }} - {{ item.titulo }} </template>
      </v-select>
    </Selecionar>
    <v-row class="pt-0">
      <v-col cols="12" md="6" lg="6" xl="6">
        <v-row class="pb-5">
          <v-col cols="12" class="py-0">
            <v-select
              dense
              hide-details
              :items="leiloes"
              item-text="nome"
              v-model="leilaoSelecionado"
              return-object
              background-color="#ffffff"
              single-line
              outlined
              @change="changeLeilao"
              :loading="loadingLeiloes"
              label="LEILÃO"
            >
              <template v-slot:item="{ item }">
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>{{ item.nome }} -{{ item.titulo }}</v-list-item-title>
                    <v-list-item-subtitle>
                      <v-icon small>mdi-calendar</v-icon>&nbsp;{{ item.dataLeilao | formataDataHora }}&nbsp;&nbsp;|&nbsp;&nbsp;<v-icon
                        >$vuetify.icons.iconId</v-icon
                      >{{ item.id }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="mt-2"></v-divider>
              </template>
              <template v-slot:selection="{ item }">
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title v-if="!mobile">{{ item.nome }} - {{ item.titulo }}</v-list-item-title>
                    <v-list-item-title v-if="mobile">{{ item.nome }}</v-list-item-title>
                    <v-list-item-subtitle v-if="mobile">
                      <small>{{ item.titulo }}</small>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                      <v-icon small>mdi-calendar</v-icon>&nbsp;{{ item.dataLeilao | formataDataHora }}&nbsp;&nbsp;|&nbsp;&nbsp;<v-icon
                        >$vuetify.icons.iconId</v-icon
                      >{{ item.id }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-select>
            <!-- <span v-else><b>Leilão:</b> {{ leilaoSelecionado.nome }}</span> -->
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="6" lg="6" xl="6">
        <v-row>
          <v-col cols="12" md="6" lg="6" xl="6" class="py-0" :align="!mobile ? 'left' : 'center'">
            <p>
              <span class="font-weight-bold">Qtd. Lotes: </span><span>{{ leilaoSelecionado.qtdLotes }}</span>
            </p>
            <!-- <p><span class="font-weight-bold">Descrição: </span>{{ leilaoSelecionado.titulo }}</p> -->
            <p><span class="font-weight-bold">Comitente: </span>{{ leilaoSelecionado.nomeEmpresa }}</p>
          </v-col>
          <v-col cols="12" md="6" lg="6" xl="6" class="py-0" :align="!mobile ? 'left' : 'center'">
            <p>
              <span class="font-weight-bold">Localização: </span><span>{{ leilaoSelecionado.localizacao }} </span>
            </p>
            <p><span class="font-weight-bold">Data: </span>{{ leilaoSelecionado.dataLeilao | formataDataHora }}</p>
          </v-col>
        </v-row>
      </v-col>
      <!-- <v-col cols="12" md="6" lg="6" xl="6" class="pa-4" :align="!mobile ? 'right' : 'center'">
        <div class="youtube" v-html="leilaoSelecionado.linkYoutube" v-if="leilaoSelecionado.linkYoutube"></div>
        <v-img v-else max-width="300" :src="require('@/assets/youtubeOffline.jpg')"></v-img>
      </v-col> -->
    </v-row>

    <v-row>
      <!-- PRÓXIMOS LOTES -->
      <v-col cols="12" sm="6" md="3" lg="3" xl="3" class="py-5" :order="mobile ? 2 : 1" align="center">
        <h3 class="text-center">PRÓXIMOS LOTES ({{ totalAndamento }})</h3>

        <div id="virtual" class="virtual-scroll" @scroll="onScroll">
          <!-- <v-card height="200" v-for="n in 20" :key="n">
          {{n}}
        </v-card> -->
          <CardLote v-for="(lote, i) in proximosLotes" :key="i" :lote="lote" :foto="false" @click="mostrarLote(lote)" />

          <div class="text-center">
            <v-btn icon :loading="loadingProximosLotes"> </v-btn>
          </div>
        </div>
      </v-col>

      <!-- EM LEILÃO -->
      <v-col cols="12" md="6" lg="6" xl="6" class="py-5" :order="mobile ? 1 : 2">
        <v-row justify="space-between">
          <h3>EM LEILÃO</h3>

          <div v-if="presencial">
            <v-btn
              block
              small
              color="success"
              class="mb-1"
              @click="comando('emPregao')"
              v-show="emLeilao && !emLeilao.info.emFechamento && emLeilao.info.status != 'Encerrado'"
            >
              <v-icon dark left> mdi-play </v-icon>
              Iniciar Pregão</v-btn
            >
            <v-btn
              block
              small
              color="info"
              class="mb-1"
              @click="comando('doulheuma')"
              v-show="emLeilao && emLeilao.info.emFechamento && emLeilao.info.douLhe == 0 && emLeilao.info.status != 'Encerrado'"
            >
              <v-icon dark left> mdi-gavel </v-icon>
              Dou-lhe uma</v-btn
            >
            <v-btn
              block
              small
              color="warning"
              class="mb-1"
              @click="comando('doulheduas')"
              v-show="emLeilao && emLeilao.info.emFechamento && emLeilao.info.douLhe == 1 && emLeilao.info.status != 'Encerrado'"
            >
              <v-icon dark left> mdi-gavel </v-icon>
              Dou-lhe duas</v-btn
            >
            <v-btn
              block
              small
              color="error"
              class="mb-1"
              @click="comando('homologar')"
              v-show="emLeilao && emLeilao.info.emFechamento && emLeilao.info.douLhe == 2 && emLeilao.info.status != 'Encerrado'"
              :loading="encerrando"
              :disabled="encerrando"
            >
              <v-icon dark left> mdi-stop </v-icon>
              encerrar
            </v-btn>
          </div>
          <div v-if="!presencial" class="d-flex justify-end" style="gap: 8px">
            <v-btn
              color="success"
              small
              class="mb-1"
              @click="comando('iniciar')"
              v-show="emLeilao && !emLeilao.info.emFechamento && emLeilao.info.status != 'Encerrado'"
            >
              <v-icon dark left> mdi-play </v-icon>
              Iniciar
            </v-btn>
            <v-btn color="primary" small class="mb-1" @click="comando('iniciar')" v-show="emLeilao && emLeilao.info.emFechamento && !countStatus">
              <v-icon dark left> mdi-reload </v-icon>
              Reiniciar
            </v-btn>
            <v-btn color="warning" small class="mb-1" @click="comando('parar')" v-show="emLeilao && emLeilao.info.emFechamento && !countStatus">
              <v-icon dark left> mdi-pause </v-icon>
              parar</v-btn
            >

            <v-btn color="error" class="mb-1" small @click="comando('encerrar')" v-show="countStatus" :loading="encerrando">
              <v-icon dark left> mdi-stop </v-icon>
              encerrar
            </v-btn>
          </div>
        </v-row>

        <v-card elevation="0" rounded="lg">
          <v-card-text class="px-2">
            <v-row v-show="mobile" class="mx-0 mb-3 mobile" justify="space-between">
              <div class="card-info">
                <p class="text--secondary">LOTE</p>
                <v-sheet height="20" v-if="!emLeilao">
                  <v-skeleton-loader type="card" height="20"></v-skeleton-loader>
                </v-sheet>
                <p class="font-weight-black" v-else>
                  {{ emLeilao.numeroLote }}
                </p>
              </div>
              <div class="card-info d-flex flex-column align-end">
                <span class="text--secondary">TEMPO RESTANTE </span
                ><span :style="emLeilao && emLeilao.info.emFechamento ? 'color: #ff5252 !important' : 'color: #bbbbbb'">
                  <span class="font-weight-black" v-if="emLeilao && countStatus">{{ countStatus }}</span>
                  <span class="font-weight-black" v-if="emLeilao && emLeilao.info && emLeilao.info.status.includes('Aguardando') && !countStatus">{{
                    tempoRestante
                  }}</span>
                </span>

                <count-down
                  v-if="emLeilao && emLeilao.info.emFechamento && !countStatus"
                  :final="emLeilao.info.dataEncerramento"
                  :atual="emLeilao.info.dataAtualUtc"
                  @FinishCountdown="onFinishCountDown"
                />
              </div>
            </v-row>
            <v-row justify="space-between" class="ma-0">
              <div class="card-info hidden-sm-and-down">
                <p class="text--secondary">LOTE</p>
                <v-sheet height="20" v-if="!emLeilao">
                  <v-skeleton-loader type="card" height="20"></v-skeleton-loader>
                </v-sheet>
                <p class="font-weight-black" v-else>
                  {{ emLeilao.numeroLote }}
                </p>
              </div>
              <div class="card-info">
                <p class="text--secondary">LANCE ATUAL</p>
                <v-sheet height="20" width="100" v-if="!emLeilao">
                  <v-skeleton-loader type="card" width="100" height="20"></v-skeleton-loader>
                </v-sheet>
                <p class="font-weight-black" v-else>R$ {{ emLeilao && emLeilao.info.ultimoLance | formatMoeda }}</p>
              </div>
              <div class="card-info" :class="{ 'text-right': mobile }">
                <p class="text--secondary">ARREMATANTE</p>
                <v-sheet height="20" v-if="!emLeilao">
                  <v-skeleton-loader type="card" height="20"></v-skeleton-loader>
                </v-sheet>
                <p class="font-weight-black" v-else>
                  {{ emLeilao && emLeilao.info.usuarioUltimoLance }}
                </p>
              </div>
              <div class="card-info hidden-sm-and-down">
                <p class="text--secondary text-right" v-if="presencial">STATUS</p>
                <p class="text--secondary" v-if="!presencial">TEMPO RESTANTE</p>
                <p
                  class="font-weight-black text-right color-aguardando"
                  :class="{
                    'color-homologando': countStatus === 'Homologando...',
                    'color-encerrado':
                      (emLeilao && emLeilao.info.emFechamento && countStatus === '') ||
                      (emLeilao && emLeilao.info && emLeilao.info.status.includes('Encerrado')),
                    'green--text': countStatus === 'Em Pregão',
                    'color-doulheuma': countStatus === 'Dou-lhe uma',
                    'color-doulheduas': countStatus === 'Dou-lhe duas',
                  }"
                >
                  <span v-if="emLeilao && emLeilao.info && emLeilao.info.status.includes('Encerrado')">{{ emLeilao.info.status }}</span>
                  <span
                    v-else-if="
                      ((emLeilao && emLeilao.info && emLeilao.info.status.includes('Aguardando')) ||
                        (emLeilao && emLeilao.info && emLeilao.info.status.includes('Aberto'))) &&
                      !countStatus
                    "
                    >{{ tempoRestante }}</span
                  >
                  <span v-else-if="emLeilao && countStatus">{{ countStatus }}</span>
                  <count-down
                    v-else-if="emLeilao && emLeilao.info.emFechamento && !countStatus"
                    :final="emLeilao.info.dataEncerramento"
                    :atual="emLeilao.info.dataAtualUtc"
                    @FinishCountdown="onFinishCountDown"
                  />
                </p>
              </div>
            </v-row>
            <v-row>
              <v-col cols="12" md="8" lg="8" xl="8" class="pl-0" :class="mobile && 'pa-0 pt-3'">
                <v-card rounded="lg" class="clicavel" style="position: relative">
                  <v-carousel hide-delimiters height="260">
                    <v-carousel-item v-if="loading">
                      <v-sheet class="pa-3">
                        <v-skeleton-loader class="mx-auto" type="image"></v-skeleton-loader>
                      </v-sheet>
                    </v-carousel-item>
                    <v-carousel-item
                      v-else
                      v-for="(foto, i) in emLeilao.fotos"
                      :key="i"
                      :src="foto && foto.arquivo.url"
                      @click="zoom(foto && foto.arquivo.url, i)"
                    >
                      <span class="tag-leilao">
                        {{ emLeilao && emLeilao.descricao }}
                      </span>
                    </v-carousel-item>
                  </v-carousel>
                </v-card>
                <v-row>
                  <v-col cols="4" md="4">
                    <div class="d-flex align-center justify-center my-1">
                      <div class="mr-4">
                        <img :src="require('@/assets/icon1.png')" style="width: 30px" />
                      </div>

                      <div>
                        <p class="font-weight-black text-h6">
                          {{ emLeilao && emLeilao.info.qtdParticipantes }}
                        </p>
                        <p class="text-caption">Participantes</p>
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="4" md="4">
                    <div class="d-flex align-center justify-center mb-1">
                      <div class="mr-4">
                        <img :src="require('@/assets/icon2.png')" style="width: 30px" />
                      </div>

                      <div>
                        <p class="font-weight-black text-h6">
                          {{ emLeilao && emLeilao.info.qtdVisualizacoes }}
                        </p>
                        <p class="text-caption">Visualizações</p>
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="4" md="4">
                    <div class="d-flex align-center justify-center mb-1">
                      <div class="mr-4">
                        <img :src="require('@/assets/icon3.png')" style="width: 30px" />
                      </div>

                      <div>
                        <p class="font-weight-black text-h6">
                          {{ emLeilao && emLeilao.info.qtdLances }}
                        </p>
                        <p class="text-caption">Lances</p>
                      </div>
                    </div>
                  </v-col>
                </v-row>
                <div v-if="presencial && emLeilao && emLeilao.info.status != 'Encerrado'">
                  <h4 class="mb-3 titulo-presencial">LANCE PRESENCIAL</h4>
                  <div class="d-flex justify-space-around align-center flex-wrap" style="gap: 10px">
                    <div
                      class="button-presencial"
                      tittle="Enviar Lance Presencial"
                      @click="fazerLance(emLeilao.info.ultimoLance + emLeilao.valorIncremento, emLeilao.loteId)"
                    >
                      <span class="button-presencial-x"> <span>1X</span></span>
                      <span class="button-presencial-corpo">
                        <P class="button-presencial-valor">{{
                          (emLeilao.info.ultimoLance + emLeilao.valorIncremento)
                            | currency('R$', 0, {
                              spaceBetweenAmountAndSymbol: true,
                              decimalSeparator: ',',
                              thousandsSeparator: '.',
                            })
                        }}</P>
                      </span>
                    </div>
                    <div
                      class="button-presencial"
                      tittle="Enviar Lance Presencial"
                      @click="fazerLance(emLeilao.info.ultimoLance + emLeilao.valorIncremento * 2, emLeilao.loteId)"
                    >
                      <span class="button-presencial-x"> <span>2X</span></span>
                      <span class="button-presencial-corpo">
                        <P class="button-presencial-valor">{{
                          (emLeilao.info.ultimoLance + emLeilao.valorIncremento * 2)
                            | currency('R$', 0, {
                              spaceBetweenAmountAndSymbol: true,
                              decimalSeparator: ',',
                              thousandsSeparator: '.',
                            })
                        }}</P>
                      </span>
                    </div>
                    <div
                      class="button-presencial hidden-sm-and-down"
                      tittle="Enviar Lance Presencial"
                      @click="fazerLance(emLeilao.info.ultimoLance + emLeilao.valorIncremento * 3, emLeilao.loteId)"
                    >
                      <span class="button-presencial-x"> <span>3X</span></span>
                      <span class="button-presencial-corpo">
                        <P class="button-presencial-valor">{{
                          (emLeilao.info.ultimoLance + emLeilao.valorIncremento * 3)
                            | currency('R$', 0, {
                              spaceBetweenAmountAndSymbol: true,
                              decimalSeparator: ',',
                              thousandsSeparator: '.',
                            })
                        }}</P>
                      </span>
                    </div>
                  </div>
                  <v-row class="mt-3 mb-1">
                    <v-col cols="12" md="7">
                      <vuetify-money v-model="valorLancePresencial" label="Outro Valor" dense outlined v-bind:options="options" />
                    </v-col>
                    <v-col cols="12" md="5" class="d-flex justify-space-around flex-wrap">
                      <v-btn color="error" fab x-small :disabled="getLancePresencialLoading" title="Diminuir Valor do Lance" @click="diminuirLance()">
                        <v-icon dark> mdi-minus </v-icon>
                      </v-btn>
                      <v-btn
                        color="success"
                        fab
                        x-small
                        :disabled="getLancePresencialLoading"
                        title="Aumentar Valor de Lance"
                        @click="aumentarLance()"
                      >
                        <v-icon dark> mdi-plus </v-icon>
                      </v-btn>
                      <v-btn
                        color="info"
                        @click="fazerLance(valorLancePresencial, emLeilao.loteId)"
                        :disabled="getLancePresencialLoading"
                        :loading="getLancePresencialLoading"
                        title="Enviar Lance Presencial"
                      >
                        <v-icon dark left> mdi-send </v-icon>Enviar
                      </v-btn>
                    </v-col>
                  </v-row>
                </div>
              </v-col>
              <v-col cols="12" md="4" lg="4" xl="4" class="caracteristicas" :class="mobile ? 'pa-0' : ''">
                <div class="pt-3">
                  <p class="text-center mb-2" style="color: #2c75ea; font-weight: bold">
                    {{ emLeilao && emLeilao.descricao }}
                  </p>
                  <p v-for="(item, i) in emLeilao && emLeilao.campos" :key="i" style="line-height: 18px">
                    <span class="font-weight-bold">{{ item.loteCampo.descricao }}: </span><span class="text--secondary">{{ item.valor }}</span>
                  </p>
                  <p class="font-weight-black text-caption pt-3">DESCRIÇÃO DETALHADA</p>
                  <p
                    class="text--secondary text-caption"
                    style="font-size: 11px !important; line-height: 1.4"
                    v-html="emLeilao && emLeilao.descricaoDetalhada"
                  ></p>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- LOTES LEILOADOS -->
      <v-col cols="12" sm="6" md="3" lg="3" xl="3" class="py-5" align="center" order="3">
        <h3>LOTES LEILOADOS ({{ totalLeiloados }})</h3>

        <div id="virtualLeiloados" class="virtual-scroll" @scroll="onScrollLeiloados">
          <CardLote v-for="(lote, i) in lotesLeiloados" :key="i" :lote="lote" :foto="false" @click="mostrarLote(lote)" />
          <v-btn icon :loading="loadingLotesLeiloados"> </v-btn>
        </div>
      </v-col>
    </v-row>
    <v-dialog v-model="zoomImg" max-width="720" max-height="360" v-if="zoomImg">
      <v-carousel v-model="itemCarosel">
        <v-carousel-item v-for="(foto, i) in emLeilao.fotos" :key="i" :src="foto && foto.arquivo.url" aspect-ratio="2"></v-carousel-item>
      </v-carousel>
    </v-dialog>
    <v-dialog v-model="dialogCartela" max-width="400" persistent>
      <v-card>
        <v-card-title class="text-h5"><v-icon left> mdi-numeric-10-box-multiple </v-icon> Informar Cartela</v-card-title>
        <v-card-text>
          <v-form ref="form" @submit.prevent="submit">
            <v-container>
              <v-row>
                <v-col cols="12">
                  <p>O último lance foi de um usuário presencial, informe o numero da cartela para encerrar o lote!</p>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    label="Cartela"
                    required
                    v-model="form.cartela"
                    :rules="[(val) => (val || '').length > 0 || 'Campo obrigatório']"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="comando('encerrarPresencial')"
            :loading="encerrandoPresencial"
            :disabled="!formIsValid"
            type="submit"
            >Confirmar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Leilao from '@/service/leilao';
import CardLote from '../components/card-lote.vue';
import CountDown from '../components/CountDown.vue';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import moment from 'moment';
import sweetalertMixins from '../../mixins/sweetalertMixin';
import Selecionar from './selecionar.vue';

export default {
  name: 'PainelLeiloeiro',
  components: {
    CardLote,
    CountDown,
    Selecionar,
  },
  mixins: [sweetalertMixins],
  data() {
    const defaultForm = Object.freeze({ cartela: '' });
    return {
      form: Object.assign({}, defaultForm),
      defaultForm,
      zoomImg: false,
      dialogCartela: false,
      urlImg: '',
      itemCarosel: 0,
      contadorLote: {
        total: 0,
      },
      contadores: {
        lances: 0,
      },
      leiloes: [],
      loadingLeiloes: true,
      leilaoSelecionado: {},
      lotes: null,
      leiloados: null,
      emLeilao: null,
      proximosLotes: [],
      lotesLeiloados: [],
      loadingProximosLotes: false,
      loadingLotesLeiloados: false,
      countStatus: '',
      loading: true,
      mostrarIniciar: false,
      encerrando: false,
      encerrandoPresencial: false,
      totalLeiloados: 0,
      totalAndamento: 0,
      options: {
        locale: 'pt-BR',
        prefix: 'R$',
        suffix: '',
        length: 11,
        precision: 2,
      },
      valorLancePresencial: 0,
    };
  },

  computed: {
    ...mapGetters(['getAcao', 'getAcaoLoteAtivo', 'getLeilao', 'getLancePresencialLoading', 'getLancePresencialError']),
    widownHeight() {
      return window.innerHeight * 0.9;
    },
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    tempoRestante() {
      const tempo = this.leilaoSelecionado.tempoInicioSeg;
      const format = tempo >= 60 ? 'm[m] : s[s]' : 's[s]';
      return moment('00:00', 'mm:ss').add(tempo, 's').format(format);
    },
    selecionar() {
      return !this.leilaoSelecionado.nome;
    },
    presencial() {
      const pregao = ['Presencial', 'Hibrido'];
      return this.leilaoSelecionado && pregao.includes(this.leilaoSelecionado.tipoPregao);
    },
    formIsValid() {
      return this.form.cartela;
    },
  },

  methods: {
    ...mapActions([
      'setAuth',
      'iniciar',
      'parar',
      'encerrar',
      'getLoteInfo',
      'notificarPregao',
      'homologarLote',
      'encerrarPresencial',
      'lancePresencial',
    ]),
    ...mapMutations(['setTituloPagina']),

    comando(acao) {
      switch (acao) {
        case 'iniciar':
          if (moment().utc().isSameOrBefore(moment(this.leilaoSelecionado.dataLeilao).utc())) {
            this.showToast({ type: 'error', title: 'Aguarde a data e hora do leilão' });
            return;
          }
          this.iniciar(this.emLeilao.loteId).catch((x) => {
            this.showToast({ type: 'error', title: x.Message });
          });
          this.countStatus = null;
          break;
        case 'parar':
          this.confirm('Realmente deseja parar o lote?').then((result) => {
            if (result.isConfirmed) {
              this.parar(this.emLeilao.loteId).catch((x) => {
                this.showToast({ type: 'error', title: x.Message });
              });
              this.countStatus = null;
            }
          });
          break;
        case 'encerrar':
          this.encerrando = true;
          this.encerra().catch((x) => {
            this.showToast({ type: 'error', title: x.Message });
          });
          break;
        case 'emPregao':
          if (moment().utc().isSameOrBefore(moment(this.leilaoSelecionado.dataLeilao).utc())) {
            this.showToast({ type: 'error', title: 'Aguarde a data e hora do leilão' });
            return;
          }
          this.notificarPregao({ doulhe: 0, loteId: this.emLeilao.loteId }).catch((x) => {
            this.showToast({ type: 'error', title: x.Message });
          });
          break;
        case 'doulheuma':
          this.notificarPregao({ doulhe: 1, loteId: this.emLeilao.loteId }).catch((x) => {
            this.showToast({ type: 'error', title: x.Message });
          });
          break;
        case 'doulheduas':
          this.notificarPregao({ doulhe: 2, loteId: this.emLeilao.loteId }).catch((x) => {
            this.showToast({ type: 'error', title: x.Message });
          });
          break;
        case 'homologar':
          this.encerrando = true;
          this.homologarLote({ loteId: this.emLeilao.loteId })
            .then(() => {
              if (this.emLeilao.info.usuarioUltimoLance === 'presencial') {
                this.dialogCartela = true;
                this.encerrando = false;
              } else {
                this.comando('encerrar');
              }
            })
            .catch((x) => {
              this.showToast({ type: 'error', title: x.Message });
            });

          break;
        case 'encerrarPresencial':
          this.encerrandoPresencial = true;
          this.encerrarPresencial({ cartela: this.form.cartela, loteId: this.emLeilao.loteId })
            .then(() => {
              this.encerrandoPresencial = false;
              this.dialogCartela = false;
              this.form.cartela = null;
            })
            .catch((x) => {
              this.showToast({ type: 'error', title: x.Message });
              this.encerrandoPresencial = false;
              this.dialogCartela = false;
              this.form.cartela = null;
            });
          break;
      }
    },
    zoom(url, index) {
      this.urlImg = url;
      this.itemCarosel = parseInt(index);
      this.zoomImg = true;
    },
    mostrarLote(lote) {
      this.$nextTick(() => this.setEmleilao(lote));
    },
    async encerra() {
      try {
        await this.encerrar(this.emLeilao.loteId);
      } catch (error) {
        this.showToast({ title: 'Aguardando encerramento' });
      }
      this.encerrando = false;
    },
    async acaoAoEncerrar() {
      if (this.proximosLotes.length > 1) {
        this.setEmleilao(this.proximosLotes[1]);
      }
      const { id } = this.leilaoSelecionado;
      const lotes = await Leilao.getLotes({ id, page: 1, status: 'aberto' });
      this.lotes = lotes;
      this.proximosLotes = lotes.data;
      const leiloados = await Leilao.getLotes({
        id,
        page: 1,
        status: 'encerrado',
      });

      this.lotesLeiloados = leiloados.data;
      this.totalLeiloados = leiloados.totalRecords;
      this.totalAndamento = lotes.totalRecords;

      this.encerrando = false;
    },
    async addLotes(id) {
      let page = this.lotes ? this.lotes.pageNumber + 1 : 1;

      if (page > 1 && this.lotes && page > this.lotes.totalPages) return;

      this.loadingProximosLotes = true;
      const lotes = await Leilao.getLotes({ id, page, status: 'aberto' });
      this.lotes = lotes;
      this.totalAndamento = lotes.totalRecords;
      lotes.data.forEach((e) => this.proximosLotes.push(e));

      this.loadingProximosLotes = false;
    },
    async addLotesLeiloados(id) {
      const page = this.leiloados ? this.leiloados.pageNumber + 1 : 1;

      if (page > 1 && this.leiloados && page > this.leiloados.totalPages) return;

      this.loadingLotesLeiloados = true;
      const lotes = await Leilao.getLotes({ id, page, status: 'encerrado' });
      this.leiloados = lotes;
      this.totalLeiloados = lotes.totalRecords;

      lotes.data.forEach((e) => this.lotesLeiloados.push(e));

      this.loadingLotesLeiloados = false;
    },
    async onChangeLeilao() {
      this.countStatus = '';
      const { id } = this.leilaoSelecionado;
      this.lotes = null;
      await this.addLotes(id);
      this.setEmleilao();
      await this.addLotesLeiloados(id);
    },
    async changeLeilao() {
      this.lotes = null;
      this.countStatus = '';
      const { id } = this.leilaoSelecionado;
      this.onJoinGroup(id);
      this.loadingProximosLotes = true;
      const lotes = await Leilao.getLotes({ id, page: 1, status: 'aberto' });
      this.lotes = lotes;
      this.totalAndamento = lotes.totalRecords;
      this.proximosLotes = lotes.data;
      this.loadingProximosLotes = false;

      this.setEmleilao();

      this.loadingLotesLeiloados = true;
      const lotesLeiloados = await Leilao.getLotes({
        id,
        page: 1,
        status: 'encerrado',
      });
      this.totalLeiloados = lotesLeiloados.totalRecords;
      this.lotesLeiloados = lotesLeiloados.data;
      this.loadingLotesLeiloados = false;
    },
    async onFinishCountDown(finalizar) {
      if (finalizar) {
        this.countStatus = 'Homologando...';
      }
    },
    timeout() {
      return new Promise((resolve) => {
        setTimeout(resolve, 200);
      });
    },
    async setEmleilao(item = null) {
      this.loading = true;
      this.countStatus = this.presencial ? ' ' : null;
      if (!item) {
        item = this.proximosLotes.find((e) => e.info.emFechamento) || this.proximosLotes[0];
      }
      const { data } = await Leilao.getLote({ id: item.loteId });
      const resp = await Leilao.getLoteInfo({ id: item.loteId });
      this.emLeilao = data;
      this.emLeilao['info'] = { ...resp.data };
      this.emLeilao['fotos'] = {
        ...data.fotos.filter((e) => e.tipoFoto.visivelSite),
      };

      this.loading = false;

      this.countStatus = this.presencial ? resp.data.status : '';

      return;
    },

    async inicializa() {
      try {
        const leiloes = await Leilao.getLeiloesAberto();

        this.leiloes = leiloes.data;

        this.loadingLeiloes = false;

        if (this.getLeilao) {
          this.leilaoSelecionado = { ...this.getLeilao };
          this.onChangeLeilao();
          this.onJoinGroup(this.leilaoSelecionado.id);
          return;
        }

        if (this.leiloes.length == 1) {
          this.leilaoSelecionado = this.leiloes[0];

          this.onChangeLeilao();
          this.onJoinGroup(this.leilaoSelecionado.id);
        }
      } catch (_) {
        //console.error();
      }
    },
    onScroll(e) {
      this.top = e.target.scrollTop;
      const elem = document.getElementById('virtual');

      if (elem.offsetHeight + elem.scrollTop >= elem.scrollHeight) {
        this.toTop = true;
        const { id } = this.leilaoSelecionado;
        this.addLotes(id);
        return;
      }
      this.toTop = false;
      e.stopPropagation();
    },
    onScrollLeiloados(e) {
      this.top = e.target.scrollTop;
      const elem = document.getElementById('virtualLeiloados');

      if (elem.offsetHeight + elem.scrollTop >= elem.scrollHeight) {
        this.toTop = true;
        const { id } = this.leilaoSelecionado;
        this.addLotesLeiloados(id);
        return;
      }
      this.toTop = false;
      e.stopPropagation();
    },
    onJoinGroup(leilaoId) {
      this.$signalR.joinLeilao(leilaoId);
    },

    async onEventNewLance(loteinfo) {
      if (this.emLeilao.loteId === loteinfo.loteId) {
        this.emLeilao['info'] = { ...loteinfo };
        if (loteinfo.status === 'Encerrado') {
          this.acaoAoEncerrar();
          return false;
        }
        this.countStatus = null;

        if (this.presencial) {
          this.countStatus = loteinfo.status;
        } else {
          this.countStatus = '';
        }
      }
      const lote = this.proximosLotes.find((e) => e.loteId == loteinfo.loteId);
      if (lote) {
        lote.info = { ...loteinfo };
      }
      //this.countStatus = null;
    },
    async fazerLance(valor, loteId) {
      if (valor > 0) {
        await this.lancePresencial({ valor: valor, loteId: loteId });

        if (this.getLancePresencialError) {
          this.getLancePresencialError.forEach((error) => {
            this.showToast({
              type: 'error',
              title: error,
            });
          });
        } else {
          this.valorLancePresencial = 0;
        }
      }
    },
    async aumentarLance() {
      //console.log(this.valorLancePresencial);
      this.valorLancePresencial =
        this.valorLancePresencial > 0
          ? Number(this.valorLancePresencial) + this.emLeilao.valorIncremento
          : this.emLeilao.info.ultimoLance + this.emLeilao.valorIncremento;
    },
    async diminuirLance() {
      const valor = this.valorLancePresencial - this.emLeilao.valorIncremento;
      this.valorLancePresencial = valor <= 0 || valor < this.emLeilao.info.ultimoLance ? 0 : valor;
    },
  },
  beforeDestroy() {
    this.$signalR.$off('new-lance', this.onEventNewLance);
  },
  filters: {
    formataData(val) {
      if (!val) return '';
      return new Date(val).toLocaleString();
    },
    formataDataHora(val) {
      if (!val) return '';
      return moment(val).utc().local().format('DD/MM/YYYY HH:mm');
    },
    formatMoeda(val) {
      if (!val) return '';
      return val.toLocaleString('pt-br', { minimumFractionDigits: 2 });
    },
  },
  async created() {
    this.setTituloPagina('PAINEL LEILÃO');
    await this.inicializa();
    this.$signalR.$on('new-lance', this.onEventNewLance);
  },
};
</script>

<style>
.titulo-presencial {
  border-bottom: 1px solid #ccc;
}

.button-presencial {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #848b9d;
  border-radius: 5px;
  border: 1px solid #848b9d;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
}

.button-presencial-x {
  background-color: #ccc;
  align-self: stretch;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  color: #000;
  font-size: 18px;
  border-radius: 5px 0 0 5px;
}

.button-presencial-corpo {
  padding: 15px;
}

.button-presencial-valor {
  font-size: 20px;
}

.row-emleilao {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

p {
  margin: 0px !important;
}

.tag-leilao {
  position: absolute;
  z-index: 9999;
  padding: 5px 15px 5px 15px;
  background-color: rgba(255, 255, 255, 0.6);
  color: #222;
  font-weight: bold;
  border-bottom-right-radius: 5px;
  letter-spacing: 1px;
  max-width: 70%;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.virtual-scroll {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 645px;
}

.virtual-scroll::-webkit-scrollbar {
  display: none;
}

.card-info div {
  display: flex;
  justify-content: flex-end;
}

.card-info .text--secondary {
  font-size: 11px;
}

.card-info .font-weight-black {
  font-size: 20px;
}

.caracteristicas {
  max-height: 450px;
  overflow-y: auto;
  overflow-x: hidden;
}

.caracteristicas span {
  font-size: 14px;
}

.caracteristicas::-webkit-scrollbar {
  width: 0.2em;
}

.caracteristicas::-webkit-scrollbar-track {
  background: rgb(248, 248, 248); /* color of the tracking area */
}

.caracteristicas::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.caracteristicas::-webkit-scrollbar-thumb {
  background-color: rgb(141, 139, 139);
}

.clicavel {
  cursor: pointer;
}

.painel {
  font-size: 13px;
}

.mobile .text--secondary {
  font-size: 11px;
}

iframe {
  width: 100%;
  height: 100%;
}

.youtube {
  width: 320px;
  height: 180px;
  border: 3px solid #fff;
  box-shadow: 0px 7px 5px 0px #00000060;
}

.color-aguardando {
  color: #bbbbbb;
}

.color-homologando {
  color: #f9c718 !important;
}

.color-doulheuma {
  color: #2196f3 !important;
}

.color-doulheduas {
  color: #fb8c00 !important;
}

.color-encerrado {
  color: #e0280f !important;
}

.v-list-item {
  overflow: hidden;
}
</style>
